<template>
    <login-page class="login-page">
        <v-card
            class="mx-auto no-bg"
            flat
            max-width="304"
            min-width="304">
            <div class="logo-wrapper">
                <brand-logo/>
                <div class="separation-line"/>
            </div>

            <validation-observer v-slot="{ handleSubmit }" ref="form">
                <v-form
                    class="auth-form"
                    @submit.prevent="handleSubmit(submitRegForm)">
                    <div
                        v-if="regError"
                        class="error pa-2 mb-4 text--white">
                        {{ regError }}
                    </div>
                    <div
                        v-if="resetStatus.success"
                        class="success pa-2 mb-4 text--white">
                        {{ resetStatus.message }}
                    </div>

                    <div class="d-flex">
                        <div class="half">
                            <validation-provider
                                v-slot="{ errors }"
                                mode="eager"
                                name="First Name"
                                vid="first_name"
                                rules="required|max:255"
                                tag="label">
                                <div class="text-body-1 mb-2">
                                    First Name
                                </div>
                                <v-text-field
                                    class="input"
                                    v-model="credentials.first_name"
                                    height="34"
                                    hide-details="auto"
                                    outlined
                                    placeholder="First Name"
                                    type="text"/>
                                <div v-if="errors.length" class="text-body-1 text--error mt-1">
                                    {{ errors[0] }}
                                </div>
                            </validation-provider>
                        </div>
                        <div class="half">
                            <validation-provider
                                v-slot="{ errors }"
                                mode="eager"
                                name="Last Name"
                                vid="last_name"
                                rules="required|max:255"
                                tag="label">
                                <div class="text-body-1 mb-2">
                                    Last Name
                                </div>
                                <v-text-field
                                    class="input"
                                    v-model="credentials.last_name"
                                    height="34"
                                    hide-details="auto"
                                    outlined
                                    placeholder="Last Name"
                                    type="text"/>
                                <div v-if="errors.length" class="text-body-1 text--error mt-1">
                                    {{ errors[0] }}
                                </div>
                            </validation-provider>
                        </div>
                    </div>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Email"
                        vid="email"
                        rules="required|email"
                        tag="label">
                        <div class="text-body-1 mb-2">
                            Email
                        </div>
                        <v-text-field
                            class="input"
                            v-model="credentials.email"
                            height="34"
                            hide-details="auto"
                            outlined
                            placeholder="Email"
                            type="email"/>
                        <div v-if="errors.length" class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Password"
                        vid="password"
                        rules="min:8|max:64|required"
                        tag="label">
                        <div class="text-body-1 mt-4 mb-2">
                            Password
                        </div>
                        <v-text-field
                            class="input"
                            v-model="credentials.password"
                            height="34"
                            hide-details="auto"
                            outlined
                            placeholder="Password"
                            type="password"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] || passwordHasOnlySpecialSymbols }}
                        </div>
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Confirm Password"
                        vid="password_confirmation"
                        rules="confirmed:password|required"
                        tag="label">
                        <div class="text-body-1 mt-4 mb-2">
                            Confirm Password
                        </div>
                        <v-text-field
                            class="input"
                            v-model="credentials.password_confirmation"
                            height="34"
                            hide-details="auto"
                            outlined
                            placeholder="Confirm Password"
                            type="password"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <vue-recaptcha
                        ref="recaptcha"
                        load-recaptcha-script
                        :sitekey="siteKey"
                        class="mt-4"
                        size="300"
                        theme="white"
                        @expired="resetRecaptcha"
                        @verify="onVerify"/>

                    <span class="btn-auth-wrapper mt-4 mb-2">
                        <v-btn
                            :loading="loading"
                            :disabled="loadingGoogle"
                            block
                            class="create-account-btn text-h6 v-btn--auth text-capitalize px-13"
                            color="#9FF4A9"
                            depressed
                            height="34"
                            type="submit">
                            Create Your {{ buttonRegisterText }} Account
                        </v-btn>
                    </span>
                    <span class="btn-auth-wrapper mt-2 mb-2" v-if="!disableGmailAuth">
                        <v-btn
                            block
                            :disabled="loading"
                            :loading="loadingGoogle"
                            class="google-auth-btn text-h6 v-btn--auth text-capitalize px-13 d-inline-flex align-center"
                            color="#fffff"
                            depressed
                            height="34"
                            @click.prevent="submitRegGoogleForm()">
                            <img
                                :src="appGoogleLogo"
                                width="18"
                                height="18"
                                class="mr-2"
                                alt="Google logo">
                            Sign Up with Google
                        </v-btn>
                    </span>
                    <router-link
                        class="auth-form__text-link text-body-2 mt-3 pl-2"
                        replace
                        :to="{ query: {
                            type: isDesignerRegister ? 'client' : 'designer'
                        } }">
                        Switch to <b>{{ buttonSwitchText }}</b> registration page.
                    </router-link>
                </v-form>
            </validation-observer>
        </v-card>
    </login-page>
</template>

<script>
    import mixinAuth from "@/views/user/login/mixin-auth";
    import BrandLogo from "@/views/user/login/BrandLogo";
    import axios from "axios";
    import { apiRegisterClient } from "@/api/authorizations.api";
    import VueRecaptcha from "vue-recaptcha";
    import { ValidationProvider, extend } from "vee-validate";
    import { max } from "vee-validate/dist/rules";
    import LoginPage from "./login-page";
    import { mapActions } from "vuex";

    extend("max", {
        ...max,
        message: "{_field_} must have less than {length} symbols."
    });

    export default {
        name: "registration",
        mixins: [mixinAuth],
        components: {
            LoginPage,
            VueRecaptcha,
            BrandLogo,
            ValidationProvider
        },
        data: () => ({
            loading: false,
            loadingGoogle: false,
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY || "undefined",
            isSecaptcha: null,
            url: "",
            credentials: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                password_confirmation: ""
            },
            regError: "",
            regForm: true,
            resetStatus: {
                success: false,
                message: ""
            }
        }),
        created() {
            this.credentials.email = this.$route.query.email || "";
        },
        mounted() {
            window.addEventListener("message", this.onMessage, false);
            window.ReCaptchaLoaded = this.recaptchaLoaded;
        },
        beforeDestroy() {
            window.removeEventListener("message", this.onMessage);
        },
        methods: {
            ...mapActions([
                "setToken"
            ]),
            async submitRegGoogleForm() {
                this.loadingGoogle = true
                try {
                    const config = {
                        params: {
                            role: this.roleRegistration
                        }
                    };

                    if (this.$route.query.project_id) {
                        config.query = {
                            project_id: this.$route.query.project_id
                        };
                    }

                    const response = await axios.get(process.env.VUE_APP_BASE_API_URL + `/authorizations/google`, config);
                    window.location.href = response.data.data.link;
                }catch (e) {
                    console.error(e)
                } finally {
                    this.loadingGoogle = false
                }
            },
            onMessage(e) {
                if (e.origin !== window.origin || !e.data.token) {
                    return;
                }
            },
            async submitRegForm() {
                if (!this.passwordHasOnlySpecialSymbols) {
                    this.loading = true
                    try {
                        const response = await apiRegisterClient({
                            ...this.credentials,
                            role: this.roleRegistration,
                            project_id: +this.$route.query.project_id || null
                        });

                        if (response && response.data && response.data) {
                            this.regError = response.data.message;
                        }

                        if (response.data.errors) {
                            this.$refs.form.setErrors(response.data.errors);
                        } else {
                            this.afterSubmitForm(response.data);
                        }
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.loading = false
                    }
                }
            },
            afterSubmitForm(data) {
                if(this.$route.query.project_id) {
                    this.setToken(data)
                    return this.$router.replace({ name: "dashboard" });
                }
                this.$router.push({
                    name: "login",
                    query: {
                        emailConfirmed: 0,
                        project_id: this.$route.query.project_id || ""
                    }
                });
            },
            recaptchaLoaded() {
                window.render(this.$refs.recaptcha, {
                    sitekey: this.sitekey,
                    "data-badge": "bottomright",
                    callback: response => {
                        this.$emit("input", response);
                    }
                });
            },
            onVerify() {
                this.isSecaptcha = true;
            },
            onExpired() {
                this.isSecaptcha = false;
            },
            resetRecaptcha() {
                this.$refs.recaptcha.reset();
            }
        },
        computed: {
            disableGmailAuth() {
                const { project_id, email } = this.$route.query;
                if (!project_id || !email) return false;

                const mailBox = email.split("@")[1];
                return project_id && (mailBox === "gmail.com" || mailBox === "googlemail.com");
            },
            passwordHasOnlySpecialSymbols() {
                const specialSymbols = [
                    "!",
                    "@",
                    "#",
                    "$",
                    "%",
                    "^",
                    "&",
                    "*",
                    "("
                ];
                if (
                    this.credentials.password &&
                    this.credentials.password.length > 0
                ) {
                    const passwordHasSomeNonSpecialSymbols = this.credentials.password
                        .split("")
                        .some(passwordSymbol => {
                            let passwordSymbolDoesntMatchAnySpecialSymbol = true;
                            specialSymbols.forEach(specialSymbol => {
                                if (passwordSymbol == specialSymbol) {
                                    passwordSymbolDoesntMatchAnySpecialSymbol = false;
                                }
                            });

                            return passwordSymbolDoesntMatchAnySpecialSymbol;
                        });

                    return passwordHasSomeNonSpecialSymbols
                        ? null
                        : "Password cannot contain only special characters";
                }
                return null;
            },
            roleRegistration() {
                return this.isDesignerRegister ? "designer" : "client"
            },
            isDesignerRegister() {
                return this.$route.query.type && this.$route.query.type === "designer"
            },
            buttonSwitchText() {
                return !this.isDesignerRegister ?  "3D Designer" : "Client"
            },
            buttonRegisterText() {
                return this.isDesignerRegister ?  "3D Designer" : "Client"
            }
        }
    };
</script>

<style lang="scss">
.auth-form {
    position: relative;

    .create-account-btn {
        border: 1px solid #464646;
        box-sizing: border-box;
        border-radius: 50px;
        font-weight: 400 !important;
    }
}

.half {
    display: inline-block;
    width: calc(50% - 13px);

    &:nth-child(even) {
        margin-left: 26px;
    }
    padding: 0 0 13px 0;

    .text--error {
        max-width: 139px;
    }
}

.input {
    border: 1px solid #464646;
    border-radius: 0;
}

.google-auth-btn {
    background-color: transparent !important;
}
.login-page {
    .logo-wrapper {
        max-width: 112px;
        margin: 0 auto 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .separation-line {
            width: 50px;
            height: 1px;
            background-color: #464646;
        }
    }
}
.no-bg {
    background-color: transparent !important;
    background: transparent;
}

.auth-form__text-link {
    display: block;
    color: #0046af !important;
    text-align: center;
    font-family: "Futura PT",  sans-serif !important;
}
</style>
